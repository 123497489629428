import angular from "angular";
import moment from "moment";
import find from "lodash/find";
import get from "lodash/get";

import { fetchDocumentById } from "@skryv/core-ng1/core/store/actions/documents";
import { selectDocument } from "@skryv/core-ng1/core/store/selectors/documents";
import { fetchLVPerceelReflist } from "@skryv/bundle/customizations/actions/vrfLV";

import template from "./vrfNietPerceel.html";
import "./vrfNietPerceel.scss";

const namespace = "vrf/components/document/vrfNietPerceel";

angular
  .module(namespace, [])
  .factory("vrfNietPerceel", function ($timeout, $q, $ngRedux) {
    "ngInject";

    return {
      template: template,
      link: linkPerceel,
    };

    function linkPerceel(scope, input, manipulator) {
      function findComponent(list, name) {
        return find(list, (component) => {
          const options = component.inputOptions;
          return options.name === name;
        });
      }

      // We grab the nested components from the current fieldset,
      // and the nested manipulators.

      const components = scope.component.components;
      const { perceel, teelt, type_schade, aantal, eenheidsprijs } =
        scope.manipulator.propertyManipulators;

      // Link the components and manipulators.
      scope.perceel = {
        component: findComponent(components, "perceel"),
        manipulator: perceel,
      };

      scope.teelt = {
        component: findComponent(components, "teelt"),
        manipulator: teelt,
      };

      scope.type_schade = {
        component: findComponent(components, "type_schade"),
        manipulator: type_schade,
      };

      scope.aantal = {
        component: findComponent(components, "aantal"),
        manipulator: aantal,
      };

      scope.eenheidsprijs = {
        component: findComponent(components, "eenheidsprijs"),
        manipulator: eenheidsprijs,
      };

      // perceel referencelist (we don't do anything with the baseUrl or reflistName, but the docmod will pass this to us)
      let fetchReferencelist =
        (documentId, kboNumberOfFarmer, year, disasterCode) =>
        (baseUrl, reflistName, cb) => {
          if (!documentId || !kboNumberOfFarmer || !year || !disasterCode) {
            console.error(
              "No documentId, kbo number or code or year of disaster present; could not fetch L&V perceel referencelist"
            );
            return $q.when([]);
          }
          return resolveDossierIdForDocument(documentId).then((dossierId) => {
            return $ngRedux
              .dispatch(
                fetchLVPerceelReflist(
                  dossierId,
                  kboNumberOfFarmer,
                  year,
                  disasterCode
                )
              )
              .then((state) => {
                return cb(undefined, get(state, ["api", "response", "data"]));
              })
              .catch((error) => {
                cb(error);
              });
          });
        };

      let resolveDossierIdForDocument = (documentId) => {
        // usually the document will be saved in the redux state, so we can use find it there
        let document = selectDocument($ngRedux.getState(), documentId);
        let dossierId = get(
          find(get(document, "links"), { resourceType: "DOSSIER" }),
          "resourceId"
        );
        if (dossierId) return $q.when(dossierId);

        // otherwise, we need to fetch the document from the backend
        return $ngRedux
          .dispatch(fetchDocumentById(documentId))
          .then((state) => {
            let lookupBackend = selectDocument(state, documentId);
            let dossierId = get(
              find(get(lookupBackend, "links"), { resourceType: "DOSSIER" }),
              "resourceId"
            );
            return $q.when(dossierId);
          });
      };

      let kboNumberOfFarmer = manipulator.computedExpressions.aanvrager_id;
      let rampStartdatum = moment(
        manipulator.computedExpressions.ramp_startdatum,
        "DD-MM-YYYY"
      );
      let year = rampStartdatum.year();
      if (rampStartdatum.isBefore(moment(`06-01-${year}`, "DD-MM-YYYY"))) {
        year -= 1; // use last years VA
      }
      let disasterCode = manipulator.computedExpressions.ramp_code;
      let documentId = scope.document;
      scope.perceel.manipulator.loadReferencelistWithSpecificRequest(
        fetchReferencelist(documentId, kboNumberOfFarmer, year, disasterCode)
      );

      // if perceel field is selected, enrich other fields appropriately (only if they do not have a value yet to avoid changing manually overwritten values)
      scope.perceel.manipulator.state.onSetMatch((match) => {
        if (!scope.teelt.manipulator.state.currentKey()) {
          scope.teelt.manipulator.state.becomeKey(match.cropName);
        }
      });

      // if the perceel field is emptied, also empty the related fields (if they have a value)
      scope.perceel.manipulator.state.onClear(() => {
        if (scope.teelt.manipulator.state.currentKey()) {
          scope.teelt.manipulator.inputQuery("");
        }
      });
    }
  });

export default namespace;
